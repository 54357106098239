// Annotation marker container

.annotation-marker-container {
  position: fixed;
  left: 0;
  top: 0;

  .marker-point {
    position: absolute;
    top: -12px;
    left: -12px;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background-color: white;
    opacity: 0.3;
    border: 2px solid white;
    z-index: 1;
    box-shadow: $elevation2;

    .label {
      position: absolute;
      bottom: 27px;
      left: -41px;
      width: 110px;
      background: white;
      padding: 6px;
      text-align: center;
      display: none;
      box-shadow: $elevation2;
      border-radius: 4px;
    }

    &.visible {
      z-index: 2;
      opacity: 1;
      border: none;

      .label {
        display: block;
      }

      &:hover,
      &.highlighted {
        // cursor: pointer;
        background-color: $primary;
        color: white;
        .label {
          background-color: $primary;
        }
      }
    }
  }
}

.annotation-box {
  padding: 5px;
  border-bottom: 1px solid $gray-light-4;
  margin-bottom: 10px;

  &.highlighted {
    background: rgba(135, 206, 250, 0.1);
    box-shadow: 0px 0px 5px 2px inset $primary;
  }
}
