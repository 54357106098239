$sidebar-width: 60px;

textarea {
  resize: none;
}

.editor-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  .left-sidebar {
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: $sidebar-width + 2px;
    background: white;
    border-right: 1px solid $gray-light-3;
  }

  .left-sidebar-drawer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $sidebar-width;
    z-index: 3;

    /* blueprintjs drawer overrides */
    .bp3-portal {
      .bp3-drawer {
        left: $sidebar-width;
        overflow-y: auto;
      }

      .bp3-overlay-backdrop {
        background-color: rgba(16, 22, 26, 0.3);
      }
    }
  }

  .viewport-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
}

/* remove active highlights */
*,
:focus {
  outline: none;
}

.preview-mode {
  #chat-widget-container {
    display: none;
  }
}
