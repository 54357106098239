// Left sidebar

.left-sidebar {
  .bp3-button,
  .bottom-button {
    padding: 15px 10px;
    width: 60px;
    background: transparent;
    position: relative;
    &.bp3-active {
      background: lighten($primary, 48%);
      path {
        fill: $primary;
      }
    }
    svg {
      color: $black-font;
      width: 25px;
      height: 25px;
      margin-right: 0;
    }
    .bp3-icon {
      margin-right: 0;
    }

    &:hover {
      background-color: $gray-light-4;
      border-radius: 0;
    }
  }

  .bottom-group {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
  }

  .bottom-button {
    display: block;
    text-align: center;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
