// Layout player

.player-ui-container {
  $player-color: lighten($black-font, 5%);
  $player-background: #f1f1f1;
  $player-highlight: darken($player-background, 5%);
  $player-disabled: darken($player-background, 4%);
  $player-separation: 20px;
  $player-base-svg-size: 20px;
  // Utils

  .bp3-button {
    min-width: 62px;
    min-height: 62px;
  }

  .big-button {
    &.bp3-outlined {
      padding: 20px 28px;
      border-radius: $border-radius-md;
      border: none;
      background: $player-background;
      &:hover {
        background: $player-highlight;
      }
    }

    &.bp3-disabled {
      background: $player-disabled;
      svg {
        color: darken($player-background, 20%);
      }
      path {
        stroke: darken($player-background, 20%);
      }
      &:hover {
        background: $player-disabled;
      }
    }

    svg {
      width: $player-base-svg-size;
      height: $player-base-svg-size;

      path {
        fill: $player-color;
      }
    }
  }

  .player-controls {
    svg {
      path {
        stroke: $player-color;
      }
    }
  }

  // Top bar

  .top-bar {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 0;
    padding: $player-separation;

    .dropdown-menu {
      margin-left: auto;
    }
  }

  // Player controls

  .player-controls {
    position: fixed;
    right: $player-separation;
    top: 50%;
    transform: translateY(-55%);
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      align-self: center;
      margin-bottom: calc($player-separation * 0.75);
    }

    .button-forward {
      .big-button {
        border-radius: $border-radius-md;
        svg {
          width: calc($player-base-svg-size * 2.5);
          height: calc($player-base-svg-size * 2.5);
        }
      }
    }

    .button-back {
      .big-button {
        svg {
          width: calc($player-base-svg-size * 1.5);
          height: calc($player-base-svg-size * 1.5);
        }
      }
    }

    .progress-info {
      padding: 5px 10px;
      border-radius: $border-radius-md;
      background: $player-background;
      color: $player-color;
    }
  }

  // Step instructions

  .player-instructions-container {
    position: fixed;
    bottom: $player-separation;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    min-height: 70px;

    .audio-button {
      svg {
        color: #fff;
      }
    }
  }

  .player-audio-instructions {
    position: absolute;
    left: 100%;
    margin-left: calc($player-separation * 0.5);

    .big-button {
      width: 72px;
      height: 72px;
      svg {
        width: calc($player-base-svg-size * 2);
        height: calc($player-base-svg-size * 2);
      }
    }

    .audio-button {
      svg {
        width: auto;
        height: auto;
        transform: scale(1.6);
        animation: fade-in-out 2s ease forwards infinite;
        path {
          stroke: none;
        }
      }
    }
  }

  .player-text-instructions {
    border: none;
    font-size: 21px;
    text-align: center;
    min-width: 30vw;
    max-width: 60vw;
    min-height: 70px;
    color: $player-color;
    background: $player-background;
    border-radius: $border-radius-md;
    padding: 20px;
  }

  // Phone portrait

  @media screen and (max-width: 680px) and (orientation: portrait) {
    .bp3-button {
      min-width: 70px;
      min-height: 70px;
    }
    .player-controls {
      flex-direction: row;
      top: auto;
      bottom: 0;
      width: 100%;
      justify-content: space-between;
      transform: none;
      left: 0;
      padding: 16px;
      .bp3-button {
        width: 70px;
        height: 70px;
        margin: 0;
      }

      .button-back,
      .button-forward,
      .progress-info {
        margin-bottom: 0;
      }

      .progress-info {
        padding: 8px 14px;
      }

      .button-back {
        order: -1;
      }

      .button-forward {
        .big-button {
          svg {
            width: calc($player-base-svg-size * 1.5);
            height: calc($player-base-svg-size * 1.5);
          }
        }
      }
    }
    .player-instructions-container {
      padding: 0 16px;
      bottom: 100px;
      width: 100%;
      justify-content: center;
    }

    .player-text-instructions {
      font-size: 16px;
      max-width: none;
      flex: 1 1 auto;
      min-height: 70px;
      padding: 12px 8px;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      + .player-audio-instructions {
        > .big-button {
          margin-left: 16px;
        }
      }
    }

    .player-audio-instructions {
      position: relative;
      left: auto;
      margin: 0;
      .big-button {
        width: 70px;
        height: 70px;
      }
      .audio-button svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
